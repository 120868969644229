<template>
  <PenyertaanModalForm mode="Tambah" module="Daftar Penyertaan Modal Pada Perusahaan Afiliasi"> </PenyertaanModalForm>
</template>

<script>
import PenyertaanModalForm from './form';

const PenyertaanModalAdd = {
  name: 'PenyertaanModalAdd',
  components: { PenyertaanModalForm },
};

export default PenyertaanModalAdd;
</script>
